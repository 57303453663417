import React from "react";
import { useLocation } from "@reach/router";

// Components
import SecondaryLanding from "../components/layouts/secondary-landing";
import Seo from "../components/seo/seo";

//styles
import styles from "./make-an-appointment.module.scss";

const MakeAnAppointment = () => {
  const SEOData = {
    title: "Make an Appointment",
    meta: [
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  let bookingURL = "https://outlook.office365.com/owa/calendar/";
  let searchName = "";

  if (searchParams != null) {
    searchName = searchParams.get("BranchName") || searchParams.get("bankerName");
    
    if (searchName != null && searchName.length > 0) {
      bookingURL = bookingURL + searchName + "@wafd.onmicrosoft.com/bookings/";
    }
  }
  return (
    <SecondaryLanding>
      <Seo {...SEOData} />
      {searchName && searchName.length > 0 && (
        <iframe
          title="Make an Appointment"
          className={`border-0 ${styles.bookingsContent}`}
          src={bookingURL}
          scrolling="no"
          sandbox="allow-scripts allow-popups allow-modals allow-forms allow-same-origin allow-popups-to-escape-sandbox"
          width="100%"
          height="2000"
        ></iframe>
      )}
    </SecondaryLanding>
  );
};

export default MakeAnAppointment;
